import * as React from "react";
import { Select, MenuItem, FormControl } from '@material-ui/core';

export default function CommonDropDwn(props) {
  const [value, setValue] = React.useState("");
  const [list] = React.useState(props.value);
  const handleChange = (event) => {
    setValue(event.target.value);
    props.selectCost(event.target.value, props.index);
  };

  React.useEffect(() => {
    setValue(list[0].amount);
  }, []);
  return (
    <FormControl sx={{ m: 1, width: 200 }} size="small">
      <Select
        value={props.selectedIndex ? props.selectedEnrollFee : value}
        style={{ backgroundColor: "#fff", padding: 5, color: "#787885" }}
        disabled={props.disabled}
        onChange={(e) => handleChange(e)}
      >
        {list.map((row, index) => (
          <MenuItem value={row.amount}>Enrollment Fee - ${row.amount}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
